.sessionize-loader,
.sz-speakers--wall,
.sz-root {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  width: 100%;
  margin: 30px 0;


  * h2 {
    @apply text-[18px];
  }
}
.sz-session__title, .sz-session__speakers {
  text-transform: capitalize;

  a {
    text-decoration: none;

    &:hover {
      text-decoration-color: var(--border-primary);
    }
  }
}
.sz-speaker {
  padding: 0 32px 32px 0;
  width: 200px;
}
.sz-speaker__name {
  @apply font-body text-xl;
  padding: 4px 0;
}
.sz-speaker__tagline {
  @apply text-sm;
}
.sz-cssgrid__track-label {
  /* display: none; */
  /* visibility: hidden; */
}
.sz-cssgrid {
  width: 100%;
}
.sz-powered-by {
  display: none;
}


[data-sessionid="6b239d43-9395-44e4-80be-4e8e7f71baa9"],
[data-sessionid="712b171e-2c2e-4a1e-8b1f-5da4102ad62d"],
[data-sessionid="b4cf4dc2-3a63-4b4c-9967-5eed99ad606b"] {
  h3 {
    display: none;
  }
}
.sz-cssgrid__track-label.sz-room {
  @apply font-display text-[18px];
}