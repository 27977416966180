/* If you need to add @import statements, do so up here */

@import "./components/base.css" layer(components);
@import "./components/nav.css" layer(components);
@import "./components/typography.css" layer(components);

@import "./themes/railsconf.css" layer(theme);

@import "./home.css";
@import "./location.css";
@import "./faq.css";
@import "./sponsors.css";
@import "./shared/button.css";
@import "./sessionize.css";

/* triggers frontend rebuilds */
/* uncomment for development */
@import "jit-refresh.css";

@import url("https://use.typekit.net/rgx5rme.css");
@import url("https://fonts.googleapis.com/css2?family=Red+Hat+Display:ital,wght@0,300..900;1,300..900&display=swap");

/* Set up Tailwind imports */
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  h2 {
    @apply text-[24px] lg:text-[32px] font-extrabold uppercase;
  }
  h3 {
    @apply text-[24px] lg:text-[28px] font-extrabold uppercase;
  }
  a {
    @apply underline underline-offset-2 font-bold;
  }
  p {
    @apply text-[18px] leading-7;
  }
}

section {
  @apply w-full p-8 md:p-32;
  @apply mx-auto max-w-screen-xl px-10 sm:px-6 lg:px-8;
}
.section-heading {
  @apply font-display text-4xl sm:text-5xl md:text-6xl font-bold;
}

:root {
  /* --body-background: #f2f2f2; */
  --body-color: #444;
  --heading-color: black;
  --highlight-color: #fccd00;
}

body > header img {
  display: inline-block;
  width: 400px;
  max-width: 100%;
}

body > nav ul {
  margin: 2rem 0;
  padding: 0;
  list-style-type: none;
  display: flex;
  justify-content: center;
  gap: 1.5rem;
  font-size: 1.3rem;
  font-weight: bold;
}
body > footer ul {
  margin: 2rem 0;
  padding: 0;
  list-style-type: none;
  display: flex;
  font-weight: normal;
}

hr {
  border: none;
  border-top: 2px dotted #bbb;
  margin: 3rem 0;
}

.x-padded {
  @apply mx-auto max-w-screen-xl px-10 sm:px-6 lg:px-8;
}

#menu-icon:hover #menu-middle {
  @apply -rotate-[3deg];
}

.dot {
  @apply absolute transition-all ease-in-out;
  @apply bg-paper bg-blend-soft-light bg-repeat bg-center;
  @apply bg-[length:200%_200%];
  @apply w-[120px] h-[120px] rounded-full;
  z-index: 1;
}

#speakers {
  > div:nth-child(3n + 1) {
    @apply bg-pink-200;

    > img {
      @apply border-blue-600;
    }
  }
  > div:nth-child(3n + 2) {
    @apply bg-blue-200;

    > img {
      @apply border-yellow-500;
    }
  }
}
